@import "../../../styles/main.scss";

.gravityForm {
  display: flex;
  flex-direction: column;
  .labelGroup {
    // border: 2px solid red;
    display: flex;
    justify-content: space-between;
    span {
      color: $primary;
      align-self: flex-end;
      font-size: 1.3rem;
    }
  }
  input,
  textarea,
  .labelGroup label {
    border-radius: 0.4rem;
    font-size: 1.8rem;
    @include medium-devices {
      font-size: 2rem;
    }
  }
  input,
  textarea {
    border: 0.096rem solid #95989a;
  }
  input {
    height: 4.8rem;
    margin-bottom: 1.6rem;
    padding: 0.5rem 1rem;
  }
  textarea {
    margin-bottom: 1.6rem;
    padding: 0.5rem 1rem;
  }
  .buttonGroup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 1.3rem;
    span {
      font-size: 2rem;
    }
    button,
    a {
      border: none;
      white-space: nowrap;
      cursor: pointer;
      background-color: $paragraph;
      border-radius: 8px;
      color: #ffffff;
      font-size: 1.8rem;
      min-width: 18rem;
      height: 3.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 40px;
      @include medium-devices {
        min-width: 276px;
        font-size: 2.8rem;
      }
    }
  }
  .radioGroup {
    margin-bottom: 20px;
    @include medium-devices {
      margin-bottom: 0;
    }
    .radioButton {
      label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
        input[type="radio"] {
          padding: 0;
          vertical-align: middle;
          margin: 0 0 0 20px;
          width: 20px;
        }
      }
    }
  }
}
